import { useId, useRef, useState } from 'react'

import { useDebounceValue } from '~/utils/misc.tsx'
import {
	dateFormatFunctions,
	dateParseFunctions,
	dateValidationFunctions,
	getFormatDescription,
	getFormatTitle,
	type FormatName,
} from '~/utils/timeformat-names.ts'
import { Icon } from './ui/icon.tsx'

interface TimestampProps {
	initialTimestamp: string
	setSharedEditTimestamp: Function
	formatName: FormatName
}

export function Timestamp({
	initialTimestamp,
	setSharedEditTimestamp,
	formatName,
}: TimestampProps): JSX.Element {
	const title = getFormatTitle(formatName)
	const description = getFormatDescription(formatName)
	const id = useId()
	const formatter = dateFormatFunctions[formatName]
	const isValid = dateValidationFunctions[formatName]
	const dateParse = dateParseFunctions[formatName]

	const formattedInitialTimestamp = formatter(initialTimestamp)
	const lastInitialTimestampRef = useRef(formattedInitialTimestamp)
	const [editTimestamp, setEditTimestamp] = useState<string>(
		formattedInitialTimestamp,
	)
	const debouncedEditTimestamp = useDebounceValue(editTimestamp, 500)
	const timestampError = !isValid(debouncedEditTimestamp)

	let displayTimestamp = editTimestamp
	if (
		lastInitialTimestampRef.current !== formattedInitialTimestamp &&
		editTimestamp !== formattedInitialTimestamp
	) {
		// initialTimestamp prop has changed since the last render
		displayTimestamp = formattedInitialTimestamp
		lastInitialTimestampRef.current = formattedInitialTimestamp
		setEditTimestamp(formattedInitialTimestamp)
	}
	const inputId = id + '-input'
	const errorMessageId = id + '-error-message'
	const descriptionId = id + '-description'

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value
		setEditTimestamp(value)
		if (isValid(value) && value !== formattedInitialTimestamp) {
			// Debounce set shared edit state somehow?
			const newDate = dateParse(value)
			setSharedEditTimestamp(newDate)
		}
	}

	const copyToClipboard = () => {
		navigator.clipboard.writeText(editTimestamp)
	}

	return (
		<div className="flex flex-col">
			{/* Time format name */}
			<label
				htmlFor={inputId}
				className="block text-sm font-medium leading-6 sm:text-base"
				aria-describedby={descriptionId}
			>
				{title}
			</label>
			<p
				className="grow text-sm text-gray-500 dark:text-gray-400"
				id={descriptionId}
			>
				{description}
			</p>
			{/* Time format input */}
			<div className="mt-2 flex justify-self-end rounded-md">
				<input
					id={id + '-input'}
					type="text"
					value={displayTimestamp}
					onChange={handleChange}
					className="block w-full rounded-l-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500 sm:text-sm sm:leading-6"
					aria-describedby={`${descriptionId}${
						timestampError ? ` ${errorMessageId}` : ''
					}`}
				/>
				<button
					type="button"
					className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-none rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 transition duration-150  ease-in-out hover:bg-gray-50 dark:hover:bg-gray-800 dark:active:bg-gray-600"
					aria-label={'copy timestamp ' + displayTimestamp}
					onClick={copyToClipboard}
				>
					<Icon
						name="copy"
						className="-ml-0.5 h-5 w-5 text-gray-400"
						aria-hidden="true"
					/>
				</button>
			</div>
			<p
				className={`mt-1 text-xs text-red-500 dark:text-red-400 sm:text-sm ${
					!timestampError ? 'invisible' : ''
				}`}
				id={errorMessageId}
			>
				Invalid format
			</p>
		</div>
	)
}

export default Timestamp

import { useState } from 'react'
import { json } from '@remix-run/node'
import { useLoaderData } from '@remix-run/react'
import type { DataFunctionArgs } from '@sentry/remix/types/utils/vendor/types.js'

import Timestamp from '~/components/timestamp.tsx'
import { Icon } from '~/components/ui/icon.tsx'
import { getHints } from '~/utils/client-hints.tsx'
import {
	getClientLocaleFromAcceptLanguageHeader,
	getDateTimeFormat,
} from '~/utils/misc.tsx'
import { FormatName } from '~/utils/timeformat-names.ts'

// const schema = z.object({
// 	email: z.string().min(1, 'Email is required').email('Email is invalid'),
// 	password: z.string().min(1, 'Password is required'),
// })

export async function loader({ request }: DataFunctionArgs) {
	const date = new Date()
	return json({
		timeZone: getHints(request).timeZone,
		locales: getClientLocaleFromAcceptLanguageHeader(request),
		dateDisplay: getDateTimeFormat(request).format(date),
		isoStringDate: date.toISOString(),
	})
}

// export async function action({ request }: ActionArgs) {
// 	const formData = await request.formData()
// 	// Take timestamp and try to create a new valid timestamp. Return the new valid timestamp.
// 	const submission = parse(formData, { schema })

// 	if (!submission.value || submission.intent !== 'submit') {
// 		return json(submission, { status: 400 })
// 	}

// 	return await authenticate(submission.value)
// 	// redirect to url-encode new date
// }

// En datastruktur med forskjellige formater.
// const defaultOptions: Intl.DateTimeFormatOptions = {
// 	year: 'numeric',
// 	month: 'numeric',
// 	day: 'numeric',
// 	hour: 'numeric',
// 	minute: 'numeric',
// }

export default function TimeRoute() {
	const data = useLoaderData<typeof loader>()
	const [sharedEditTimestamp, setSharedEditTimestamp] = useState(
		new Date(data.isoStringDate),
	)
	//console.log('Timezone: ' + data.timeZone)
	//console.dir('locales: ' + data.locales)
	// const timeZone = data.timeZone
	// const date = new Date(data.dateDisplay)
	// const dateTimeFormat = new Intl.DateTimeFormat(data.locales[0], {
	// 	...defaultOptions,
	// 	timeZone,
	// })
	// date.toLocaleDateString(data.locales[0])
	//const options = dateTimeFormat.resolvedOptions()
	//console.dir(options)
	//console.dir(Intl.supportedValuesOf('timeZone')) // array of supported timezones.

	return (
		<main>
			<div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
				<div className="flex w-full justify-center">
					<section className="w-76 mx-auto inline-flex flex-col rounded-md p-4 ring-1 ring-blue-200 dark:bg-gray-900 dark:ring-blue-600">
						<h1>Your browser</h1>
						<span
							className="flex flex-col text-sm text-foreground/90"
							title={data.dateDisplay}
						>
							<div className="flex items-center gap-2">
								<Icon
									name="clock"
									className="flex-shrink-0 scale-125  text-gray-600 dark:text-gray-400"
								>
									Time:
								</Icon>
								<time dateTime={data.dateDisplay} className="flex-1 text-right">
									{data.dateDisplay}
								</time>
							</div>
							<div className="flex items-center gap-2">
								<Icon
									name="laptop"
									className="flex-shrink-0 scale-125  text-gray-600 dark:text-gray-400"
								>
									Timezone:
								</Icon>
								<span className="flex-1 text-right">{data.timeZone}</span>
							</div>
							<div className="flex items-center gap-2">
								<Icon
									name="globe"
									className="flex-shrink-0 scale-125  text-gray-600 dark:text-gray-400"
								>
									Locales:
								</Icon>
								<span className="flex-1 text-right">
									{data.locales.join(', ')}
								</span>
							</div>
						</span>
					</section>
				</div>
				<div className="mt-4">
					<div className="grid grid-cols-1 gap-x-4 gap-y-1 sm:grid-cols-2 sm:gap-y-2 lg:grid-cols-3">
						{/* Content goes here */}
						{Object.values(FormatName).map(formatName => (
							<Timestamp
								key={formatName}
								initialTimestamp={sharedEditTimestamp.toISOString()}
								setSharedEditTimestamp={setSharedEditTimestamp}
								formatName={formatName}
							></Timestamp>
						))}
					</div>
				</div>
			</div>
			{/* Map over an array of formats */}

			{/* <form>
				<label htmlFor="party">
					Enter a date and time for your party booking:
				</label>
				<input id="party" type="datetime-local" name="partydate" />
			</form>
			<form>
				<label htmlFor="party">ISO 8601:</label>
				<input id="party" type="text" name="partydate" />
			</form> */}
		</main>
	)
}

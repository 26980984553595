export enum FormatName {
	ISO_8601 = 'ISO 8601',
	HTTP_Date = 'HTTP-date',
	UTC = 'Coordinated Universal Time (UTC)',
	UnixSeconds = 'Unix (seconds)',
	UnixMilliseconds = 'Unix (milliseconds)',
	// Add other formats as needed
	//SQL_DateTime_Format = 'SQL DateTime Format',
}

interface FormatMetadata {
	description: string
	title: string
	readMoreLink?: string // Optional for now, can be required if needed
}

type DateFormatterFunction = (isoDateString: string) => string
type DateValidatorFunction = (input: string) => boolean
type DateParserFunction = (dateString: string) => Date | null

export const formatDetails: { [K in FormatName]: FormatMetadata } = {
	[FormatName.UnixSeconds]: {
		description: 'Number of seconds since Unix Epoch',
		title: 'Unix time (seconds)',
		readMoreLink: 'https://developer.mozilla.org/en-US/docs/Glossary/Unix_time',
	},
	[FormatName.UnixMilliseconds]: {
		description: 'Number of milliseconds since Unix Epoch',
		title: 'Unix time (milliseconds)',
		readMoreLink: 'https://developer.mozilla.org/en-US/docs/Glossary/Unix_time',
	},
	[FormatName.ISO_8601]: {
		description: 'A widely accepted international standard',
		title: 'ISO Date (ISO 8601)',
		readMoreLink: 'https://en.wikipedia.org/wiki/ISO_8601',
	},
	[FormatName.HTTP_Date]: {
		description: 'Used in HTTP headers and other web standards (RFC 1123)',
		title: 'HTTP-date',
		readMoreLink: 'https://www.rfc-editor.org/rfc/rfc9110#field.date',
	},
	[FormatName.UTC]: {
		description: 'Global time standard not affected by daylight savings.',
		title: 'Coordinated Universal Time (UTC)',
		readMoreLink: 'https://en.wikipedia.org/wiki/Coordinated_Universal_Time',
	},
}

export function getFormatDescription(format: FormatName): string {
	return formatDetails[format].description
}

export function getFormatTitle(format: FormatName): string {
	return formatDetails[format].title
}

export const dateFormatFunctions: { [K in FormatName]: DateFormatterFunction } =
	{
		[FormatName.ISO_8601]: isoDateString => {
			const date = new Date(isoDateString)
			return date.toISOString()
		},
		[FormatName.HTTP_Date]: isoDateString => {
			const date = new Date(isoDateString)
			return date.toUTCString()
		},
		[FormatName.UTC]: isoDateString => {
			const date = new Date(isoDateString)
			return date.toUTCString()
		},
		[FormatName.UnixSeconds]: isoDateString => {
			// Example implementation
			const date = new Date(isoDateString)
			return Math.floor(date.getTime() / 1000).toString()
		},
		[FormatName.UnixMilliseconds]: isoDateString => {
			const date = new Date(isoDateString)
			return date.getTime().toString()
		},
		// Implement other format functions...
	}

export const dateValidationFunctions: {
	[K in FormatName]: DateValidatorFunction
} = {
	[FormatName.ISO_8601]: input => {
		// TODO: Your validation functions can be enhanced to be more robust. For example, the ISO 8601 validator could be improved to validate a wider range of ISO 8601 formats, not just the specific format returned by toISOString(). This could include validating dates with or without time information, different levels of precision, and so on.
		const date = new Date(input)
		return !isNaN(date.getTime()) && date.toISOString() === input
	},
	[FormatName.HTTP_Date]: input => {
		const utcDate = new Date(input)
		return !isNaN(utcDate.getTime()) && utcDate.toUTCString() === input
	},
	[FormatName.UTC]: input => {
		const utcDate = new Date(input)
		// For UTC, this might need to be adjusted based on what 'input' format you expect,
		// as toUTCString() produces a specific format (e.g., "Wed, 14 Jun 2017 07:00:00 GMT")
		// which might not match all valid UTC/HTTP-date inputs.
		return !isNaN(utcDate.getTime()) && utcDate.toUTCString() === input
	},
	[FormatName.UnixSeconds]: input => {
		// Validate format: 10 digits
		if (!/^\d{10}$/.test(input)) return false

		const timestamp = parseInt(input, 10)
		// Create a date object from the Unix timestamp (seconds)
		const date = new Date(timestamp * 1000)
		if (isNaN(date.getTime())) {
			return false
		}

		// Validate the date object and check the range
		const now = new Date()
		const upperLimit = new Date()
		upperLimit.setFullYear(now.getFullYear() + 100)
		const lowerLimit = new Date(0) // Unix epoch start

		return date >= lowerLimit && date <= upperLimit
	},
	[FormatName.UnixMilliseconds]: input => {
		// Validate format: 13 digits
		if (!/^\d{13}$/.test(input)) return false

		const timestamp = parseInt(input, 10)
		// Create a date object from the Unix timestamp (milliseconds)
		const date = new Date(timestamp)
		if (isNaN(date.getTime())) {
			return false
		}

		// Validate the date object and check the range
		const now = new Date()
		const upperLimit = new Date()
		upperLimit.setFullYear(now.getFullYear() + 100) // Set upper limit as 100 years from now
		const lowerLimit = new Date(0) // Unix epoch start

		return date >= lowerLimit && date <= upperLimit
	},
}

export const dateParseFunctions: { [K in FormatName]: DateParserFunction } = {
	[FormatName.ISO_8601]: dateString => new Date(dateString),
	// ISO 8601 is directly supported
	[FormatName.HTTP_Date]: dateString => new Date(dateString),
	// HTTP-date format is directly supported
	[FormatName.UTC]: dateString => new Date(dateString),
	// UTC format is directly supported
	[FormatName.UnixSeconds]: dateString => {
		const timestamp = parseInt(dateString, 10)
		// Check for NaN and return null to handle invalid input gracefully
		if (isNaN(timestamp)) return null
		return new Date(timestamp * 1000)
	},
	[FormatName.UnixMilliseconds]: dateString => {
		const timestamp = parseInt(dateString, 10)
		// Check for NaN and return null to handle invalid input gracefully
		if (isNaN(timestamp)) return null
		return new Date(timestamp)
	},

	// Example for a custom format, adjust according to actual needs
	// [FormatName.SQL_DateTime_Format]: dateString => {
	//   // Parse SQL DateTime Format (e.g., "YYYY-MM-DD HH:MM:SS") into a Date
	//   // This is just a placeholder; actual implementation may vary
	//   const parts = dateString.match(/\d+/g); // Simple regex to extract numbers
	//   if (!parts || parts.length < 6) return null; // Basic validation
	//   const [year, month, day, hour, minute, second] = parts.map(Number);
	//   return new Date(year, month - 1, day, hour, minute, second);
	// },

	// Add other formats as needed
}
